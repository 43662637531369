<template>
    <!-- 缺货汇总 -->
    <div class="sorting-container">
        <div class="leftsorting">
            <logotop></logotop>
             <classification style="margin-top:10px" :data="classifylist" @change="changeclassifyFun"></classification>
        </div>
        <div class="rightsorting">
            <sortingform  class="sortingformbox"></sortingform>
            <div class="sortingBox" style="margin-top:10px">
               <a-collapse v-model="activeKey" expand-icon-position="right" :expandIcon="expandIcon" :style="customStyle"  accordion>
                     <a-collapse-panel key="1" header="肉类-牛肉">
                         <div v-for="(item,index) in productList" class="producttabItem" :style="{marginRight:(index==5?'0px':'8px')}">
                             <product-tab :data="item" color="#BC7B00"></product-tab>
                         </div>
                       
                     </a-collapse-panel>
                     <a-collapse-panel key="2" header="肉类-禽肉" :disabled="false" :style="customStyle">
                       <div v-for="(item,index) in productList" class="producttabItem" :style="{marginRight:(index==5?'0px':'8px')}">
                           <product-tab :data="item" color="#BC7B00"></product-tab>
                       </div>
                     </a-collapse-panel>
                     <a-collapse-panel key="3" header="肉类-鸭肉" :style="customStyle">
                       <div v-for="(item,index) in productList" class="producttabItem" :style="{marginRight:(index==5?'0px':'8px')}">
                           <product-tab :data="item" color="#BC7B00"></product-tab>
                       </div>
                     </a-collapse-panel>
                   </a-collapse>
            </div>
        </div>
        
    </div>
</template>

<script>
  import logotop from "@/components/logotop"; //顶部logo 
  import sortingform from "@/components/sortingform"; //顶部查询
  import classification from "@/components/classification"; //顶部查询
  import productTab from "@/components/productTab"; //顶部查询
  
  export default {
    name: "sorting",
    components: {
       logotop,
       sortingform,
       classification,
       productTab
        
     },
    data() {
      return {
        activeKey: ['1'],
        classifylist:[{key:'',name:'全部'},{key:1,name:'肉类'},{key:2,name:'水产海鲜'},{key:3,name:'农家蔬菜'}],
        customStyle:
               'background: #fff;color: #010101;box-shadow: 0px 2px 11px 0px rgba(177, 188, 199, 0.15);font-size: 23px;font-weight: 600;bordedr:none;overflow: hidden',
       productList:[{status:2,statusname:'待分拣',product:'腿夹肉(去骨)',progress:'0/3',ordernum:40,outstock:1},{status:1,statusname:'已分拣',product:'腿夹肉(去骨)',progress:'0/3',ordernum:40,unit:'斤',outstock:1},{status:2,statusname:'待分拣',product:'腿夹肉(去骨)',progress:'0/3',ordernum:40,unit:'斤',outstock:1},{status:2,statusname:'待分拣',product:'腿夹肉(去骨)',progress:'0/3',ordernum:40,unit:'斤',outstock:1},{status:2,statusname:'待分拣',product:'腿夹肉(去骨)',progress:'0/3',ordernum:40,unit:'斤',outstock:1},{status:2,statusname:'待分拣',product:'腿夹肉(去骨)',progress:'0/3',ordernum:40,unit:'斤',outstock:1},{status:2,statusname:'待分拣',product:'腿夹肉(去骨)',progress:'0/3',ordernum:40,unit:'斤',outstock:1}],
      }
      
    },
    methods: {
       expandIcon(props,data){
           if(data.isActive){
               return <div class="togglestyle"><a-icon type="minus-circle" class="icon"/>收起</div>
           }else{
               return <div class="togglestyle"><a-icon type="plus-circle" class="icon"/>展开</div>
           }
       },
      changeclassifyFun(e){
        console.log(e)  
      }
    }
  }
</script>

<style scoped lang="less">
    .producttabItem{
        display:inline-block;
        margin-bottom:10px
    }
    .togglestyle{
       font-size: 26px;
       font-family: PingFangSC-Semibold, PingFang SC;
       font-weight: 600;
       color: #010101;
       line-height: 32px; 
       .icon{
           color:#203EA0;
           font-size:26px;
           margin-right:15px;
       }
    }
    .leftsorting{
        width:205px;
        margin-right:10px;
    }
    .rightsorting{
        width:1047px;
    }
    .sortingBox{
        width:1047px;
        height:640px;
         overflow-y: scroll;
    }
   .sorting-container{
      background: #F1F2F2; 
      width:100%;
      display:flex;
       height:800px;
      padding:10px;
   }
   .sortingformbox{
     
   }
</style>
<style>
    .ant-collapse > .ant-collapse-item{
       box-shadow: 0px 2px 11px 0px rgba(177, 188, 199, 0.15);
       border-bottom:none;
       margin-bottom:2px;
    }
    .ant-collapse-content{
        border-top:none;
    }
   .sortingBox .ant-collapse-content > .ant-collapse-content-box{
        padding:10px 0px;
         min-height:392px;
    }
    .sortingBox .ant-collapse-content{
        background: #F1F2F2;
    }
   .sortingBox .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
        font-size: 26px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #010101;
    }
    .sortingBox .ant-collapse > .ant-collapse-item > .ant-collapse-header{
           line-height: 30px;
           font-size: 23px;
           font-family: PingFangSC-Semibold, PingFang SC;
           font-weight: 600;
           color: #010101;
           height: 60px;
    }
</style>